body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to bottom,#5EE7EA, #0099AA); */
}

html, body{
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #079eae;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(243, 232, 232);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(238, 221, 221);
}

@media only screen and (max-width: 599.95px) {
  html {
    font-size:10px
  }
}
input, textarea, select{
  color:white !important;
}
.MuiSelect-selectMenu{
  color:white !important;
}
.MuiSvgIcon-root{
  color:white
}