
.MuiOutlinedInput-notchedOutline{
    border-color: white !important;
    border:3px solid white;
}
.MuiInputLabel-outlined{
    color:white !important;
}
.MuiPhoneNumber-flagButton{
    background-color: rgba(255,255,255,0.3) !important;
}
.MuiAlert-standardSuccess {
    color: rgb(255 255 255) !important;
    background-color: rgb(41 211 41) !important;
}